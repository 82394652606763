export default {
  path: 'userlist',
  name: 'userlist',
  meta: {
    title: '客户端管理-Co-logistics',
    name: "客户端管理",
    power: "customer",
  },
  component: () => import("@/layout/components/commonmenu/index.vue"),
  children: [
    {
      path: 'usermanager',
      name: 'usermanager',
      meta: {
        power: "consumer",
        title: '客户账户管理-Co-logistics',
        name: "客户账号管理",
      },
      component: () => import("@/view/userlist/myuser.vue"),
    },
    {
      path: 'costumGroup',
      name: 'costumGroup',
      meta: {
        power: "customer_group",
        title: '客户客群管理-Co-logistics',
        name: "客户客群管理",
      },
      component: () => import("@/view/userlist/custom-group/index.vue"),
    },
    {
      path: 'userInfo',
      name: 'userInfo',
      meta: {
        power: "consumer",
        title: '客户信息管理-Co-logistics',
        name: "客户信息管理",
        noShow: true,
      },
      component: () => import("@/view/userlist/myuser/userInfo.vue"),
    },
    {
      path: 'allocation',
      name: 'allocation',
      meta: {
        power: "cargosite",
        title: '客服分配管理-Co-logistics',
        name: "官网聊天管理",
      },
      component: () => import("@/view/userlist/allocation/index.vue"),
    },
     {
      path: 'searchlog',
      name: 'searchlog',
      meta: {
        power: "customer_history",
        title: '查价记录-Co-logistics',
        name: "客户查价记录",
      },
      component: () => import("@/view/userlist/search_log.vue"),
    },
    {
      path: 'address',
      name: 'address',
      meta: {
        power: "consumer",
        title: '客户地址管理-Co-logistics',
        name: "客户地址管理",
      },
      component: () => import("@/view/userlist/address.vue"),
    },
    {
      path: 'supplier',
      name: 'supplier',
      meta: {
        power: "vendor",
        title: '供货商管理管理-Co-logistics',
        name: "客户供货商管理",
      },
      component: () => import("@/view/userlist/supplier/index.vue"),
    },
    {
      path: 'ipaddress',
      name: 'ipaddress',
      meta: {
        power: "information",
        title: 'ip地址管理-Co-logistics',
        name: "客户IP雷达管理",
      },
      component: () => import("@/view/userlist/ipaddress.vue"),
    },
    {
      path: 'custags',
      name: 'custags',
      meta: {
        power: "label",
        title: '客户标签-Co-logistics',
        name: "客户标签管理",
      },
      component: () => import("@/view/userlist/custags.vue"),
    },
    {
      path: 'notify',
      name: 'notify',
      meta: {
        power: "publish",
        title: '客户通知-Co-logistics',
        name: "账户通知管理",
      },
      component: () => import("@/view/userlist/notify.vue"),
    },
    
    {
      path: 'card',
      name: 'card',
      meta: {
        power: "customer_card",
        title: '客户卡片管理-Co-logistics',
        name: "客户卡片管理",
      },
      component: () => import("@/view/userlist/card.vue"),
    },
    {
      path: 'blogmanager',
      name: 'blogmanager',
      meta: {
        power: "blog",
        title: '博客管理-Co-logistics',
        name: "BLOG管理",
      },
      component: () => import("@/view/userlist/myblog.vue"),
    },
    {
      path: 'talktags',
      name: 'talktags',
      meta: {
        title: '常用语管理-Co-logistics',
        power: "fast",
        name: "常用语管理",
      },
      component: () => import("@/view/userlist/talktags/index.vue"),
    },
    {
      path: 'emaiconfig',
      name: 'emaiconfig',
      meta: {
        power: "mailconfig",
        title: '邮件配置管理-Co-logistics',
        name: "邮箱配置管理",
      },
      component: () => import("@/view/userlist/email/emailConfig.vue"),
    },
    {
      path: 'emailtmp',
      name: 'emailtmp',
      meta: {
        power: "mailtemplate",
        title: '邮件模板管理-Co-logistics',
        name: "邮件模板管理",
      },
      component: () => import("@/view/userlist/email/emailTmp.vue"),
    },
    {
      path: 'emailstatus',
      name: 'emailstatus',
      meta: {
        power: "maillog",
        title: '邮件日志管理-Co-logistics',
        name: "邮件日志管理",
      },
      component: () => import("@/view/userlist/email/emailstatus.vue"),
    },
    {
      path: 'water',
      name: 'water',
      meta: {
        power: "bill",
        title: '客户流水管理-Co-logistics',
        name: "客户流水管理",
      },
      component: () => import("@/view/userlist/water.vue"),
    },
    {
      path: 'reflect',
      name: 'reflect',
      meta: {
        power: "out",
        title: '钱包提现管理-Co-logistics',
        name: "钱包提现管理",
      },
      component: () => import("@/view/userlist/reflect.vue"),
    },
    {
      path: 'recharge',
      name: 'recharge',
      meta: {
        power: "put",
        title: '钱包充值管理-Co-logistics',
        name: "钱包充值管理",
      },
      component: () => import("@/view/userlist/recharge/recharge.vue"),
    },
    {
      path: 'balance',
      name: 'balance',
      meta: {
        power: "wallet",
        title: '钱包余额管理-Co-logistics',
        name: "钱包余额管理",
      },
      component: () => import("@/view/userlist/balance.vue"),
    },
    {
      path: 'customser',
      name: 'customser',
      meta: {
        power: "service_site",
        title: '客服席位管理-Co-logistics',
        name: "客服席位管理",
      },
      component: () => import("@/view/userlist/customser.vue"),
    },
    {
      path: 'allcustom',
      name: 'allcustom',
      meta: {
        power: "service_msg_all",
        title: '客户消息总台-Co-logistics',
        name: "客户消息总台",
      },
      component: () => import("@/view/userlist/allcustom.vue"),
    },
    {
      path: 'mycustom',
      name: 'mycustom',
      meta: {
        power: "my_service_msg",
        title: '我的客户信息-Co-logistics',
        name: "我的客户信息",
        notice: 'unread_num'
      },
      component: () => import("@/view/userlist/mycustom.vue"),
    },
    {
      path: 'viplabel',
      name: 'viplabel',
      meta: {
        power: "superuser",
        title: '会员等级-Co-logistics',
        name: "会员等级管理",
      },
      component: () => import("@/view/userlist/viplabel.vue"),
    },
    {
      path: 'member',
      name: 'member',
      meta: {
        power: "exam_super",
        title: '信息审核-Co-logistics',
        name: "会员信息审核",
      },
      component: () => import("@/view/userlist/member/index.vue"),
    },
    {
      path: 'integral',
      name: 'integral',
      meta: {
        power: "score",
        title: '会员积分-Co-logistics',
        name: "会员积分管理",
      },
      component: () => import("@/view/userlist/integral.vue"),
    },
    {
      path: 'customreport',
      name: 'customreport',
      meta: {
        power: "offer",
        title: '客户标价管理-Co-logistics',
        name: "客户报价管理",
      },
      component: () => import("@/view/userlist/customreport.vue"),
    },
    {
      path: 'shopsprice',
      name: 'shopsprice',
      meta: {
        power: "price_control",
        title: '货运价格管理-Co-logistics',
        name: "货运价格管理",
      },
      component: () => import("@/view/userlist/shops/index.vue"),
    },
    {
      path: 'storelist',
      name: 'storelist',
      meta: {
        power: "warehouse",
        title: '货运中转仓管理-Co-logistics',
        name: "货运中转仓管理",
      },
      component: () => import("@/view/userlist/store.vue"),
    },
    {
      path: 'customorder',
      name: 'customorder',
      meta: {
        power: "freight_order",
        title: '货运订舱管理-Co-logistics',
        name: "货运订舱管理",
      },
      component: () => import("@/view/userlist/order/index.vue"),
    },
    {
      path: 'helpcenter',
      name: 'helpcenter',
      meta: {
        power: "help_center",
        title: '帮助中心-Co-logistics',
        name: "帮助中心管理",
      },
      component: () => import("@/view/userlist/help/index.vue"),
    },
    {
      path: 'coupon',
      name: 'coupon',
      meta: {
        power: "coupon",
        title: '折扣券管理-Co-logistics',
        name: "折扣券管理",
      },
      component: () => import("@/view/userlist/coupon/index.vue"),
    },
    {
      path: 'fastmessage',
      name: 'fastmessage',
      meta: {
        power: "feedback",
        title: '建议反馈管理-Co-logistics',
        name: "建议反馈管理",
      },
      component: () => import("@/view/userlist/fastmessage/index.vue"),
    },
    {
      path: 'activeZone',
      name: 'activeZone',
      meta: {
        power: "goods_include_shipping",
        title: '包邮商品管理-Co-logistics',
        name: "包邮商品管理",
      },
      component: () => import("@/view/userlist/activityZone/index.vue"),
    },
    {
      path: 'activity',
      name: 'activity',
      meta: {
        power: "goods_special_area_class",
        title: '专区管理-Co-logistics',
        name: "专区管理",
      },
      component: () => import("@/view/userlist/activityZone/activity.vue"),
    },
    {
      path: 'addshop',
      name: 'addshop',
      meta: {
        power: "goods_special_area",
        title: '专区商品管理-Co-logistics',
        name: "专区商品管理",
      },
      component: () => import("@/view/userlist/activityZone/addShop.vue"),
    },
    {
      path: 'editshop',
      name: 'editshop',
      meta: {
        noShow: true,
        title: '包邮商品管理-Co-logistics',
        name: "包邮商品管理",
      },
      component: () => import("@/view/userlist/activityZone/create-shop.vue"),
    },
    {
      path: 'editactiveshop',
      name: 'editactiveshop',
      meta: {
        noShow: true,
        title: '专区商品管理-Co-logistics',
        name: "专区商品管理",
      },
      component: () => import("@/view/userlist/activityZone/create-active-shop.vue"),
    },
    {
      path: 'opfs',
      name: 'opfs',
      meta: {
        power: "store",
        title: '客户店铺-Co-logistics',
        name: "客户店铺",
      },
      component: () => import("@/view/userlist/opfs/index.vue"),
    },
    {
      path: 'usercart',
      name: 'usercart',
      meta: {
        power: "shipping_cart",
        title: '购物车-Co-logistics',
        name: "购物车",
      },
      component: () => import("@/view/userlist/dropshipping/cartList.vue"),
    },
    {
      path: 'usershop',
      name: 'usershop',
      meta: {
        power: "shipping_goods",
        title: '商品管理-Co-logistics',
        name: "商品管理",
      },
      component: () => import("@/view/userlist/dropshipping/shopList.vue"),
    },
    {
      path: 'customor',
      name: 'customor',
      meta: {
        name: "客户订单管理",
        power: "shipping_order",
        title: '客户订单管理-Co-logistics'
      },
      component: () => import("@/view/userlist/customerOrder/index.vue"),
    },
    {
      path: 'shoporder',
      name: 'shoporder',
      meta: {
        name: "客户店铺订单",
        power: "store_order",
        title: '客户店铺订单-Co-logistics'
      },
      component: () => import("@/view/userlist/shopOrder/index.vue"),
    },
    {
      path: 'apiPlatform',
      name: 'apiPlatform',
      meta: {
        name: "Api授权",
        power: "store_order",
        title: 'Api授权-Co-logistics'
      },
      component: () => import("@/view/userlist/api-platform/index.vue"),
    },
  ]
}