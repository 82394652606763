import {
  saveCustomList,
  getCustomList
}from '@/api/common'
import dayjs from "dayjs";

// 读取缓存
export function getStorage(name){
  return JSON.parse(window.sessionStorage.getItem(name)) 
}

// 写入缓存
export function setStorsage(name,obj){
  window.sessionStorage.setItem(name,JSON.stringify(obj))
}

// 清除所有缓存
export function clearStorsage(){
  window.sessionStorage.clear()
}

// 清楚缓存
export function clearNameStorsage(name){
  window.sessionStorage.removeItem(name)
}

export function setLocal(name,obj){
  window.localStorage.setItem(name,JSON.stringify(obj))
}

export function getLocal(name){
  return JSON.parse(window.localStorage.getItem(name)) 
}

export function clearLocal(name){
  window.localStorage.clear()
}

export function removeLocal(name){
  window.localStorage.removeItem (name)
}

// 用于后台传回二进制数据处理成文件并下载
export const downFileStream = (blob, name) => {
  // const url = window.URL.createObjectURL(blob)
  // var binarry = []
  // binarry.push(blob)
  // const url = window.URL.createObjectURL( new Blob(binarry)) 
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  // window.URL.revokeObjectURL(url)
  URL.revokeObjectURL(url)
}

// 传入url 实现复制到粘贴板
export const getClipboardContents = async (url) =>{
  try {
    const imgURL = url   // 'https://co-logistics.cn/api/uploads/user_image/1.png'; 谷歌里只支持png
    const data = await fetch(imgURL);
    const blob = await data.blob();
    await navigator.clipboard.write([
      new ClipboardItem({
        [blob.type]: blob
      })
    ]);
    console.log('Image copied.');
  } catch (err) {
    console.error(err.name, err.message);
  }
}

// 集装箱箱号校验
export function CalculateCheckDigit (code) {
  const containerNumber = code.toUpperCase();  
    var regex = /^[A-Z]{4}\d{7}$/;
    if (!regex.test(containerNumber)) {
        return false;
    }
  const charToValue = {
      '0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9,
      'A': 10, 'B': 12, 'C': 13, 'D': 14, 'E': 15, 'F': 16, 'G': 17, 'H': 18, 'I': 19, 'J': 20,
      'K': 21, 'L': 23, 'M': 24, 'N': 25, 'O': 26, 'P': 27, 'Q': 28, 'R': 29, 'S': 30, 'T': 31,
      'U': 32, 'V': 34, 'W': 35, 'X': 36, 'Y': 37, 'Z': 38
  };

  var weights = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512];

  let sum = 0;
  for (let i = 0; i < 10; i++) {
      sum += charToValue[containerNumber[i]] * weights[i];
  }

  let checkDigit = sum % 11;
  if(checkDigit==10){
    checkDigit = 0
  }

  if(checkDigit == containerNumber[10]){
    return true
  }else{
    return false;
  }
}

// 处理自定义列表
/**
 * 
 * @param {*} 缓存字段 
 * @param {*} 获取类型 
 * @param {*} allCustomData 
 * @param {*} reset 重置
 * @returns 
 */
export async function getCustomListData(name,type,allCustomData,reset){
  if(reset){
    removeLocal(name)
  }
  // if(getLocal(name)){
  //   return getLocal(name);
  // }else{
    const res = await getCustomList({type: type});
    let targetKeys = res.data.data;
    const list = [];
    for(let i = 0; i < targetKeys.length; i++){
      const item = allCustomData.find(custom=>custom.key==targetKeys[i])
      if(item!=undefined){
        list.push(item)
      }
    }

    setLocal(name,list)
    return list;
  // }
}

// 时间格式化
export function formData(date){
  return dayjs(date).format("YYYY-MM-DD");
}


// 下载url 文件
export function downloadFile(url,name){
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  a.click();
  a.remove();
}