export default {
  path: "skuwarehouse",
  name: "skuwarehouse",
  component: () => import("@/layout/components/commonmenu/index.vue"),
  meta: {
    title: "仓库管理-Co-logistics",
    power: "warehouse",
    name: "仓库管理"
  },
  children: [
    {
      path: "skuList",
      name: "skuList",
      meta: {
        title: "sku 管理-Co-logistics",
        power: "shopify_sku",
        name: "sku 管理",
        menu: true
      },
      component: () => import("@/view/sku-warehouse/sku/index.vue")
    },
    {
      path: "installBox",
      name: "installBox",
      meta: {
        title: "预装箱管理-Co-logistics",
        power: "shopify_box",
        name: "预装箱管理",
        menu: true
      },
      component: () => import("@/view/sku-warehouse/pre-installed-box/index.vue")
    },
    {
      path: "applySite",
      name: "applySite",
      meta: {
        title: "入库申请-Co-logistics",
        power: "applySite",
        name: "入库申请",
        menu: true
      },
      component: () => import("@/view/sku-warehouse/applySite/index.vue")
    }, 
    // {
    //   path: "claim",
    //   name: "claim",
    //   meta: {
    //     title: "认领专区-Co-logistics",
    //     power: "claim",
    //     name: "认领专区",
    //     menu: true
    //   },
    //   component: () => import("@/view/sku-warehouse/claim/index.vue")
    // },
    {
      path: "warehousing",
      name: "warehousing",
      meta: {
        title: "入库管理-Co-logistics",
        power: "warehousingCo",
        name: "入库管理",
        menu: true
      },
      component: () => import("@/layout/components/warehouse/Menu.vue"),
      children: [
        {
          path: "warehousingCo",
          name: "sku-warehousingCo",
          meta: {
            title: "（协同）入库管理-Co-logistics",
            power: "warehousingCo",
            name: "（协同）入库管理",
            menu: true
          },
          component: () => import("@/view/sku-warehouse/warehousing/index.vue")
        },
        {
          path: "warehousingAgency",
          name: "sku-warehousingAgency",
          meta: {
            title: "（代理）入库管理-Co-logistics",
            power: "warehousingAgency",
            name: "（代理）入库管理",
            menu: true
          },
          component: () => import("@/view/sku-warehouse/warehousing/proxy.vue")
        }
      ]
    },
    {
      path: "inwarehous",
      name: "inwarehous",
      meta: {
        title: "库存管理-Co-logistics",
        power: "stock",
        name: "库存管理",
        menu: true
      },
      component: () => import("@/layout/components/warehouse/Menu.vue"),
      children: [
        {
          path: "inwarehousCo",
          name: "sku-inwarehousCo",
          meta: {
            title: "（协同）库存管理-Co-logistics",
            power: "stock",
            name: "（协同）库存管理",
            menu: true
          },
          component: () => import("@/view/sku-warehouse/in-warehouse/co-warehouse.vue")
        },
        {
          path: "inwarehousAgency",
          name: "sku-inwarehousAgency",
          meta: {
            title: "（代理）库存管理-Co-logistics",
            power: "stock",
            name: "（代理）库存管理",
            menu: true
          },
          component: () => import("@/view/sku-warehouse/in-warehouse/agent-warehouse.vue")
        },
      ]
    },
    {
      path: "outbound",
      name: "sku-outbound",
      meta: {
        title: "出库管理-Co-logistics",
        power: "outboundCo",
        name: "出库管理",
        menu: true
      },
      component: () => import("@/layout/components/warehouse/Menu.vue"),
      children: [
        {
          path: "outboundCo",
          name: "sku-outboundCo",
          meta: {
            title: "（协同）出库管理-Co-logistics",
            power: "outboundCo",
            name: "（协同）出库管理",
            menu: true
          },
          component: () => import("@/view/sku-warehouse/outbound/index.vue")
        },
        {
          path: "outboundAgency",
          name: "sku-outboundAgency",
          meta: {
            title: "（代理）出库管理-Co-logistics",
            power: "outboundAgency",
            name: "（代理）出库管理",
            menu: true
          },
          component: () => import("@/view/sku-warehouse/outbound/proxy.vue")
        }
      ]
    },
    // {
    //   path: "fee",
    //   name: "fee",
    //   meta: {
    //     title: "费用明细-Co-logistics",
    //     power: "fee",
    //     name: "费用明细",
    //     menu: true
    //   },
    //   component: () => import("@/view/sku-warehouse/fee/index.vue")
    // },
    {
      path: "operator",
      name: "sku-operator",
      meta: {
        title: "操作审核-Co-logistics",
        power: "operator",
        name: "操作审核",
        menu: true
      },
      component: () => import("@/view/sku-warehouse/operator/index.vue")
    },
    {
      path: "repack",
      name: "repack",
      meta: {
        title: "重新打包-Co-logistics",
        power: "repack",
        name: "重新打包",
        menu: true
      },
      component: () => import("@/layout/components/warehouse/Menu.vue"),
      children: [
        {
          path: "repackCo",
          name: "repackCo",
          meta: {
            title: "（协同）重新打包-Co-logistics",
            power: "repackCo",
            name: "（协同）重新打包",
            menu: true
          },
          component: () => import("@/view/sku-warehouse/repack/index.vue")
        },
        {
          path: "repackAgency",
          name: "repackAgency",
          meta: {
            title: "（代理）重新打包-Co-logistics",
            power: "repackAgency",
            name: "（代理）重新打包",
            menu: true
          },
          component: () => import("@/view/sku-warehouse/repack/proxy.vue")
        }
      ]
    },
    {
      path: "release",
      name: "sku-release",
      meta: {
        title: "弃货-Co-logistics",
        power: "release",
        name: "弃货",
        menu: true
      },
      component: () => import("@/layout/components/warehouse/Menu.vue"),
      children: [
        {
          path: "releaseCo",
          name: "sku-releaseCo",
          meta: {
            title: "（协同）弃货-Co-logistics",
            power: "releaseCo",
            name: "（协同）弃货",
            menu: true
          },
          component: () => import("@/view/sku-warehouse/release/index.vue")
        },
        {
          path: "releaseAgency",
          name: "sku-releaseAgency",
          meta: {
            title: "（代理）弃货-Co-logistics",
            power: "releaseAgency",
            name: "（代理）弃货",
            menu: true
          },
          component: () => import("@/view/sku-warehouse/release/proxy.vue")
        }
      ]
    },
    {
      path: "location",
      name: "location",
      meta: {
        title: "库位管理-Co-logistics",
        power: "location",
        name: "库位管理",
        menu: true
      },
      component: () => import("@/view/sku-warehouse/location/index.vue")
    },
    {
      path: "movement",
      name: "movement",
      meta: {
        title: "库位转移-Co-logistics",
        power: "movement",
        name: "库位转移",
        menu: true
      },
      component: () => import("@/view/sku-warehouse/movement/index.vue")
    }
  ]
};
