export default {
  path: "warehouse",
  name: "warehouse",
  component: () => import("@/layout/components/warehouse/index.vue"),
  meta: {
    title: "仓库管理-Co-logistics",
    power: "warehouse",
    name: "仓库管理"
  },
  children: [
    // {
    //   path: "applySite",
    //   name: "applySite",
    //   meta: {
    //     title: "入库申请-Co-logistics",
    //     power: "applySite",
    //     name: "入库申请",
    //     menu: true
    //   },
    //   component: () => import("@/view/warehouse/applySite/index.vue")
    // }, 
    {
      path: "warehousing",
      name: "warehousing",
      meta: {
        title: "入库管理-Co-logistics",
        power: "warehousing",
        name: "入库管理",
        menu: true
      },
      component: () => import("@/layout/components/warehouse/Menu.vue"),
      children: [
        {
          path: "warehousingCo",
          name: "warehousingCo",
          meta: {
            title: "（协同）入库管理-Co-logistics",
            power: "warehousingCo",
            name: "（协同）入库管理",
            menu: true
          },
          component: () => import("@/view/warehouse/warehousing/index.vue")
        },
        {
          path: "warehousingAgency",
          name: "warehousingAgency",
          meta: {
            title: "（代理）入库管理-Co-logistics",
            power: "warehousingAgency",
            name: "（代理）入库管理",
            menu: true
          },
          component: () => import("@/view/warehouse/warehousing/proxy.vue")
        },
        // {
        //   path: "warehousingSku",
        //   name: "warehousingSku",
        //   meta: {
        //     title: "（SKU）入库管理-Co-logistics",
        //     power: "warehousingSku",
        //     name: "（SKU）入库管理",
        //     menu: true
        //   },
        //   component: () => import("@/view/warehouse/warehousing/sku.vue")
        // },
        {
          path: "skuCheckInfo",
          name: "skuCheckInfo",
          meta: {
            title: "（SKU）入库管理-Co-logistics",
            name: "（SKU）入库管理",
            menu: false
          },
          component: () => import("@/view/warehouse/common/skuCheckInfo.vue")
        },
        {
          path: "skuCheckInfoList",
          name: "skuCheckInfoList",
          meta: {
            title: "仓库管理-Co-logistics",
            name: "仓库管理",
            menu: false
          },
          component: () =>
            import("@/view/warehouse/common/skuCheckInfoList.vue")
        },
        {
          path: "skuHandle",
          name: "skuHandle",
          meta: {
            title: "仓库管理-Co-logistics",
            name: "仓库管理",
            menu: false
          },
          component: () => import("@/view/warehouse/common/skuHandleForm.vue")
        },
        {
          path: "skuInfo",
          name: "skuInfo",
          meta: {
            title: "仓库管理-Co-logistics",
            name: "仓库管理",
            menu: false
          },
          component: () => import("@/view/warehouse/common/skuInfoForm.vue")
        },
        {
          path: "skuRepack",
          name: "skuRepack",
          meta: {
            title: "仓库管理-Co-logistics",
            name: "仓库管理",
            menu: false
          },
          component: () => import("@/view/warehouse/common/skuRepack.vue")
        }
      ]
    },
    // {
    //   path: "inwarehous",
    //   name: "inwarehous",
    //   meta: {
    //     title: "库存管理-Co-logistics",
    //     power: "stock",
    //     name: "库存管理",
    //     menu: true
    //   },
    //   component: () => import("@/layout/components/warehouse/Menu.vue"),
    //   children: [
    //     {
    //       path: "inwarehousCo",
    //       name: "inwarehousCo",
    //       meta: {
    //         title: "（协同）库存管理-Co-logistics",
    //         power: "stock",
    //         name: "（协同）库存管理",
    //         menu: true
    //       },
    //       component: () => import("@/view/warehouse/in-warehouse/co-warehouse.vue")
    //     },
    //     {
    //       path: "inwarehousAgency",
    //       name: "inwarehousAgency",
    //       meta: {
    //         title: "（代理）库存管理-Co-logistics",
    //         power: "stock",
    //         name: "（代理）库存管理",
    //         menu: true
    //       },
    //       component: () => import("@/view/warehouse/in-warehouse/agent-warehouse.vue")
    //     },
    //   ]
    // },
    {
      path: "claim",
      name: "claim",
      meta: {
        title: "认领专区-Co-logistics",
        power: "claim",
        name: "认领专区",
        menu: true
      },
      component: () => import("@/view/warehouse/claim/index.vue")
    },
    {
      path: "confirm",
      name: "confirm",
      meta: {
        title: "确认专区-Co-logistics",
        power: "confirm",
        name: "确认专区",
        menu: true
      },
      component: () => import("@/view/warehouse/confirm/index.vue")
    },
    {
      path: "outbound",
      name: "outbound",
      meta: {
        title: "出库管理-Co-logistics",
        power: "outbound",
        name: "出库管理",
        menu: true
      },
      component: () => import("@/layout/components/warehouse/Menu.vue"),
      children: [
        {
          path: "outboundCo",
          name: "outboundCo",
          meta: {
            title: "（协同）出库管理-Co-logistics",
            power: "outboundCo",
            name: "（协同）出库管理",
            menu: true
          },
          component: () => import("@/view/warehouse/outbound/index.vue")
        },
        {
          path: "outboundAgency",
          name: "outboundAgency",
          meta: {
            title: "（代理）出库管理-Co-logistics",
            power: "outboundAgency",
            name: "（代理）出库管理",
            menu: true
          },
          component: () => import("@/view/warehouse/outbound/proxy.vue")
        }
      ]
    },
    {
      path: "fee",
      name: "fee",
      meta: {
        title: "费用明细-Co-logistics",
        power: "fee",
        name: "费用明细",
        menu: true
      },
      component: () => import("@/view/warehouse/fee/index.vue")
    },
    {
      path: "operator",
      name: "operator",
      meta: {
        title: "操作审核-Co-logistics",
        power: "operator",
        name: "操作审核",
        menu: true
      },
      component: () => import("@/view/warehouse/operator/index.vue")
    },
    {
      path: "repack",
      name: "repack",
      meta: {
        title: "重新打包-Co-logistics",
        power: "repack",
        name: "重新打包",
        menu: true
      },
      component: () => import("@/layout/components/warehouse/Menu.vue"),
      children: [
        {
          path: "repackCo",
          name: "repackCo",
          meta: {
            title: "（协同）重新打包-Co-logistics",
            power: "repackCo",
            name: "（协同）重新打包",
            menu: true
          },
          component: () => import("@/view/warehouse/repack/index.vue")
        },
        {
          path: "repackAgency",
          name: "repackAgency",
          meta: {
            title: "（代理）重新打包-Co-logistics",
            power: "repackAgency",
            name: "（代理）重新打包",
            menu: true
          },
          component: () => import("@/view/warehouse/repack/proxy.vue")
        }
      ]
    },
    {
      path: "release",
      name: "release",
      meta: {
        title: "弃货-Co-logistics",
        power: "release",
        name: "弃货",
        menu: true
      },
      component: () => import("@/layout/components/warehouse/Menu.vue"),
      children: [
        {
          path: "releaseCo",
          name: "releaseCo",
          meta: {
            title: "（协同）弃货-Co-logistics",
            power: "releaseCo",
            name: "（协同）弃货",
            menu: true
          },
          component: () => import("@/view/warehouse/release/index.vue")
        },
        {
          path: "releaseAgency",
          name: "releaseAgency",
          meta: {
            title: "（代理）弃货-Co-logistics",
            power: "releaseAgency",
            name: "（代理）弃货",
            menu: true
          },
          component: () => import("@/view/warehouse/release/proxy.vue")
        }
      ]
    },
    {
      path: "location",
      name: "location",
      meta: {
        title: "库位管理-Co-logistics",
        power: "location",
        name: "库位管理",
        menu: true
      },
      component: () => import("@/view/warehouse/location/index.vue")
    },
    {
      path: "movement",
      name: "movement",
      meta: {
        title: "库位转移-Co-logistics",
        power: "movement",
        name: "库位转移",
        menu: true
      },
      component: () => import("@/view/warehouse/movement/index.vue")
    }
  ]
};
