

import ueserlists from './customer/customer.js' 
import asklist from './asklist/index'
import inquirylist from './inquirylist/index'
import transformlist from './transformlist/index.js'
import emaillist from './email/index.js'
import purchlist from './purch/index.js'
import warehouselist from './warehouse/index.js'
import tracklist from './track/index.js'
import pricelist from './price/index'
import financelist from './finance/index.js'
import userlist from './userlist/index';
import skuWarehouselist from './sku-warehouse/index.js';


export default {
  path: '/',
  component: () => import("@/layout/index.vue"),
  redirect: "/system",
  meta:{
    title:'协同物流-Co-logistics'
  },
  children:[
    warehouselist,
    financelist,
    tracklist,
    pricelist,
    userlist,
    skuWarehouselist,
    {
      path:'message',
      name:'message',
      meta:{
        title:'消息-Co-logistics'
      },
      component: () => import("@/view/message/index.vue"),
    },
    {
      path:'system',
      name:'system',
      meta:{
        title:'系统管理-Co-logistics',
        power: "system",
        name: "系统管理",
      },
      component: () => import("@/layout/components/commonmenu/index.vue"),
      redirect: "/system/center",
      children:[
        {
          path: 'center',
          name: "个人中心",
          meta: {
            name: "个人中心",
            title:'个人中心-Co-logistics'
          },
          component: () => import("@/view/personal/personal-center.vue"),
        },
        {
          path:'department',
          name:'department',
          meta:{
            power: "department",
            name: "部门管理",
            title:'部门管理-Co-logistics'
          },
          component: () => import("@/view/staff/bumen.vue"),
        },
        {
          path:'position',
          name:'position',
          meta:{
            power: "position",
            name: "职位管理",
            title:'职位管理-Co-logistics'
          },
          component: () => import("@/view/staff/position.vue"),
        },
        {
          path:'staff',
          name:'staff',
          meta:{
            power: "user",
            name: "员工管理",
            title:'员工管理-Co-logistics'
          },
          component: () => import("@/view/staff/staff.vue"),
        },
        {
          path:'power',
          name:'power',
          meta:{
            power: "permissions",
            name: "权限管理",
            title:'权限管理-Co-logistics'
          },
          component: () => import("@/view/staff/power.vue"),
        },
        {
          path:'dictionary',
          name:'dictionary',
          meta:{
            power: "dictionaries",
            name: "数据字典管理",
            title:'数据字典管理-Co-logistics'
          },
          component: () => import("@/view/staff/dictionary.vue"),
        },

      ]
    },
    {
      path:'personal',
      name:'personal',
      meta:{
        title:'个人设置-Co-logistics'
      },
      component: () => import("@/layout/components/Personal.vue"),
      redirect: "/personal/info",
      children: [
        {
          path: 'info',
          name: "个人设置",
          meta: {
            title:'个人设置-Co-logistics'
          },
          component: () => import("@/view/personal/set-info.vue"),
        },
        {
          path: 'barrage',
          name: "弹幕设置",
          meta: {
            title:'弹幕设置-Co-logistics'
          },
          component: () => import("@/view/personal/set-barrage.vue"),
        },
      ]
    },
    {
      path:'tranlist',
      name:'tranlist',
      meta:{
        power: "transport",
        name: "运价管理",
        title:'运价管理-Co-logistics'
      },
      component: () => import("@/layout/components/commonmenu/index.vue"),
      children:[
        ...transformlist,
      ]
    },
    {
      path:'purchase',
      name:'purchase',
      meta:{
        title:'采购管理-Co-logistics',
        name: "采购管理",
        power: "purchase",
      },
      component: () => import("@/layout/components/commonmenu/index.vue"),
      children:[
        ...purchlist
      ]
    },
    {
      path:'inquiry',
      name:'inquiry',
      meta:{
        name: "订舱管理",
        power: "sale",
        title:'订舱管理-Co-logistics'
      },
      component: () => import("@/layout/components/commonmenu/index.vue"),
      children:[
        ...inquirylist
      ]
    },
    {
      path:'askprice',
      name:'askprice',
      meta:{
        title:'单询问价-Co-logistics'
      },
      component: () => import("@/layout/components/askPrice.vue"),
      children:[
        ...asklist
      ]
    },
    // {
    //   path:'userlist',
    //   name:'userlist',
    //   meta:{
    //     title:'客户端管理-Co-logistics'
    //   },
    //   component: () => import("@/layout/components/userlist.vue"),
    //   children:[
    //     ...ueserlists
    //   ]
    // },
    {
      path:'score',
      name:'score',
      meta:{
        name: "评分管理",
        power: "score",
        title:'评分管理-Co-logistics'
      },
      component: () => import("@/layout/components/commonmenu/index.vue"),
      children:[
        {
          path:'salescore',
          name:'salescore',
          meta:{
            name: "评分管理（销售）",
            power: "score_sale",
            title:'评分管理-Co-logistics'
          },
          component: () => import("@/view/scorelist/salescore.vue"),
        },
        {
          path:'busscore',
          name:'busscore',
          meta:{
            name: "评分管理（商务）",
            power: "score_manage",
            title:'评分管理-Co-logistics'
          },
          component:() => import("@/view/scorelist/busscore.vue"),
        },
        {
          path:'opscore',
          name:'opscore',
          meta:{
            name: "评分管理（操作）",
            power: "score_opera",
            title:'评分管理-Co-logistics'
          },
          component: () => import("@/view/scorelist/opscore.vue"),
        }
      ]
    },
    {
      path:'markeingemail',
      name:'markeingemail',
      meta:{
        name: "营销邮件管理",
        power: "email",
        title:'营销邮件-Co-logistics'
      },
      component: () => import("@/layout/components/commonmenu/index.vue"),
      children:[
        ...emaillist
      ]
    }

  ]
}